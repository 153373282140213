.footer {
    width: 100%;
    height: 200px;
    background-color: #131313;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    margin-top: 200px;
}

.socialMedia svg {
    color: white;
    margin: 20px;
    font-size: 70px;
    cursor: pointer;
}

.footer p {
    color: white;
}

@media only screen and (max-width: 600px) {
    .footer svg {
        font-size: 50px;
    }
}